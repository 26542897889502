<template>
  <div>
    <v-data-table
      :headers="data.headers"
      :items="data.data"
      :item-per-page="5"
      :search="search"
      :loading="data.load"
      loading-text="Cargando datos..."
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            Mis Responsables
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="modalAdd()">
            <v-icon left>mdi-plus</v-icon>
            Nuevo Responsable
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="
                  selectResponsable(item);
                  modalUpdate();
                "
                color="amber"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
        <template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-5"
                @click="
                  selectResponsable(item);
                  modalDelete();
                "
                color="red"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </template>

      <!-- Reser data -->
      <template>
        <v-btn color="primary" @click="getResponsables()">
          Reiniciar
        </v-btn>
      </template>
    </v-data-table>

    <AddResponsable
      ref="mdlAdd"
      :responsable="newResponsable"
      :clean="cleanAdd"
    ></AddResponsable>
    <UpdateResponsable
      ref="mdlUpdate"
      :responsable="slcResponsable"
      :clean="cleanUpdate"
    ></UpdateResponsable>
    <!-- Dialog Delete -->
    <v-dialog v-model="dialogDelete" max-width="450px">
      <v-card>
        <v-card-title class="headline">Advertencia</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <p class="mt-5 mb-0">
            ¿Está seguro de eliminar este elemento?
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="closeDelete()">
            Cancelar
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteResponsableConfirm()">
            Eliminar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddResponsable from "./AddResponsable";
import UpdateResponsable from "./UpdateResponsable";

export default {
  name: "StudentResponsables",
  components: {
    AddResponsable,
    UpdateResponsable,
  },
  data: () => ({
    data: {
      load: false,
      headers: [
        { text: "Nombres", value: "first_name" },
        { text: "Apellidos", value: "last_name" },
        { text: "Celular", value: "cellphone" },
        { text: "Teléfono", value: "phone" },
        { text: "Correo", value: "email" },
        { text: "Parentesco", value: "kin" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      data: [],
    },
    dialog: false,
    newResponsable: {},
    slcResponsable: {},
    search: "",
    dialogDelete: false,
  }),
  mounted() {
    // esto deberia ir en un watch pero no funciona ahi por alguna razon
    this.getResponsables();
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    getResponsables: async function() {
      this.data.data = [];
      this.data.load = true;
      await this.$http
        .get(this.api + "responsable/all/" + this.student.id_student)
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    changeModal: function() {
      this.dialog = !this.dialog;
    },

    selectResponsable(responsable) {
      this.slcResponsable = responsable;
    },

    modalUpdate() {
      this.$refs.mdlUpdate.changeModal();
    },

    modalAdd() {
      this.newResponsable.id_student = this.student.id_student;
      this.$refs.mdlAdd.changeModal();
    },

    cleanUpdate() {
      this.slcResponsable = {};
      this.getResponsables();
    },

    cleanAdd() {
      this.newResponsable = {};
      this.getResponsables();
    },

    modalDelete() {
      this.dialogDelete = true;
    },

    deleteResponsableConfirm() {
      this.isLoading = true;
      this.$http
        .delete(this.api + "responsable/" + this.slcResponsable.id_responsable)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeDelete();
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
          this.closeDelete();
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.slcStudent = {};
      });
    },
  },
  watch: {
    student(val) {},
  },
  computed: {
    ...mapGetters({
      student: "Student/getStudent",
      api: "getApi",
      apiAdmision: "getAdmisionApi",
      apiAis: "getAisApi",
      apiCore: "getCoreApi",
    }),
  },
};
</script>

<style></style>
