<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Modificar Resposable</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                label="Nombres"
                required
                outlined
                hide-details
                v-model="responsable.first_name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                label="Apellidos"
                required
                outlined
                hide-details
                v-model="responsable.last_name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select
                label="Parentesco"
                :items="relationships"
                item-text="kin"
                item-value="id_kin"
                required
                outlined
                hide-details
                v-model="responsable.id_kin"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                label="Teléfono Celular"
                required
                outlined
                hide-details
                v-model="responsable.cellphone"
                v-mask="'####-####'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                label="Teléfono de Casa"
                type="text"
                outlined
                hide-details
                v-model="responsable.phone"
                v-mask="'####-####'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                label="Correo Electrónico"
                type="email"
                outlined
                hide-details
                v-model="responsable.email"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="changeModal()">
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="update()"
          :loading="isLoading"
        >
          Modificar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UpdateResponsableStudent",
  data() {
    return {
      dialog: false,
      isLoading: false,
      relationships: [],
    };
  },
  props: { responsable: Object, clean: { type: Function } },
  mounted() {
    this.getRelationships();
  },
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    getRelationships: function() {
      this.$http.get(this.apiAdmision + "kin/all").then(({ data }) => {
        this.relationships = data;
      });
    },
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    update: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.api + "responsable/" + this.responsable.id_responsable,
          this.toFormData(this.responsable)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.changeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      apiAdmision: "getAdmisionApi",
    }),
  },
};
</script>

<style></style>
