<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          type="password"
          label="Contraseña actual"
          required
          outlined
          hide-details
          v-model="changePassword.old_password"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          type="password"
          label="Nueva contraseña"
          required
          outlined
          hide-details
          v-model="changePassword.new_password"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          type="password"
          label="Repetir nueva contraseña"
          required
          outlined
          hide-details
          v-model="changePassword.new_password_second"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="update()" :loading="isLoading">
          <v-icon left>
            mdi-content-save
          </v-icon>
          Guardar cambios
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "StudentChangePassword",
  data: () => ({
    isLoading: false,
    changePassword: {},
  }),
  mounted() {},
  methods: {
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    update: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.api + "student/change_password",
          this.toFormData(this.changePassword)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.changePassword = {};
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      student: "Student/getStudent",
    }),
  },
};
</script>

<style></style>
