<template>
  <div>
    <v-container>
      <v-row>
        <v-col v-if="!cardLoad" cols="12" sm="12" md="5">
          <v-card height="240" v-if="student.status == 1 && !cardLoad">
            <v-card-title>
              {{ current_course.program }} {{ current_course.course }}
            </v-card-title>
            <v-card-subtitle class="pb-0">
              {{ current_course.cycle }} -
              {{ current_course.language }}
            </v-card-subtitle>
            <v-card-text class="text--primary">
              <p class="mb-0">
                <span class="font-weight-bold">Docente</span>
                {{ current_course.personal_name }}
              </p>
              <p class="mb-0">
                <span class="font-weight-bold">Correo Docente</span>
                {{ current_course.personal_email }}
              </p>
              <p class="mb-0">
                <span class="font-weight-bold">Horas</span>
                {{ current_course.hours }}
              </p>
              <p class="mb-0">
                <span class="font-weight-bold">Fechas</span>
                Desde el <b>{{ current_course.start_cycle }}</b> hasta el
                <b>{{ current_course.end_cycle }}</b>
              </p>
              <p class="mb-0">
                <span class="font-weight-bold">Horarios</span>
                <br />
                <span
                  class="mb-0"
                  v-for="(schedule, index) in current_course.course_schedules"
                  :key="index"
                >
                  {{ schedule }}
                  <br />
                </span>
              </p>
            </v-card-text>
          </v-card>
          <v-card height="240" v-if="student.status == 3">
            <v-card-title>Examen de Ubicación</v-card-title>
            <v-card-subtitle>Mide tus conocimientos</v-card-subtitle>
            <v-card-text class="text--primary">
              <p>
                Ingresa al Aula Virtual el para realizar el examen, para
                ubicarte en el mejor nivel de acuerdo a tus conocimientos.
              </p>
            </v-card-text>
          </v-card>
          <v-card height="240" v-if="student.status == 4">
            <v-card-title>Primeros Pasos</v-card-title>
            <v-card-subtitle>Comienza con las mejores bases</v-card-subtitle>
            <v-card-text class="text--primary">
              <p>
                Al realizar la matrícula al curso se habilitará el contenido en
                el aula virtual.
              </p>
              <p class="text-center">
                <v-btn color="primary" to="my_payments" link>
                  <v-icon left>
                    mdi-credit-card-settings-outline
                  </v-icon>
                  Pagos Online
                </v-btn>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12" sm="12" md="3">
          <v-card height="220">
            <v-progress-linear
              class="pt-20"
              color="primary"
              indeterminate
              rounded
              height="6"
            >
            </v-progress-linear>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-card height="240">
            <v-card-title>
              Información del Portal
            </v-card-title>
            <v-card-text class="text--primary">
              <p>
                Por medio de este portal puedes actualizar tu información
                personal, la información de tus responsables, realizar
                <b>Pagos Online</b>, acceder a tu historial de pagos y ver tu
                historial de cursos con sus calificaciones.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-card height="240">
            <v-card-title>
              Aula Virtual
            </v-card-title>
            <v-card-text class="text--primary">
              <p>
                Ingresa al Aula Virtual para acceder al contenido de las Clases,
                Actividades y Material de Apoyo.
              </p>
              <p class="text-center">
                <v-btn
                  color="primary"
                  href="https://idiomas.ricaldone.edu.sv/"
                  target="_blank"
                >
                  <v-icon left>
                    mdi-school
                  </v-icon>
                  Aula Virtual
                </v-btn>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="student.status == 4">
        <v-col cols="12" md="8" offset-md="2">
          <v-card>
            <v-card-title>
              Pasos A Seguir
            </v-card-title>
            <v-card-subtitle>
              Nuevo Ingreso - Sin Examen De Ubicación.
            </v-card-subtitle>
            <v-divider> </v-divider>
            <v-card-text>
              <p class="black--text">
                <v-icon left color="naranja">
                  mdi-numeric-1-circle
                </v-icon>
                Al registrarte sin examen de ubicación debes realizar el pago
                del primer curso.
              </p>
              <p class="black--text">
                <v-icon left color="naranja">
                  mdi-numeric-2-circle
                </v-icon>
                Al realizar el pago, se creará un usuario al día hábil siguiente
                en el Aula Virtual.
              </p>
              <p class="black--text">
                <v-icon left color="naranja">
                  mdi-numeric-3-circle
                </v-icon>
                En esta misma ventana veras tu curso actual y su información
                correspondiente.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="student.status == 3">
        <v-col cols="12" md="8" offset-md="2">
          <v-card>
            <v-card-title>
              Pasos A Seguir
            </v-card-title>
            <v-card-subtitle>
              Nuevo Ingreso - Con Examen De Ubicación.
            </v-card-subtitle>
            <v-divider> </v-divider>
            <v-card-text>
              <p class="black--text">
                <v-icon left color="naranja">
                  mdi-numeric-1-circle
                </v-icon>
                Al registrarte con examen de ubicación debes esperar dos dias
                hábiles a partir del día del registro para ingresar al Aula
                Virtual.
              </p>
              <p class="black--text">
                <v-icon left color="naranja">
                  mdi-numeric-2-circle
                </v-icon>
                Ingresar al Aula Virtual con el mismo usuario y contraseña de
                este portal.
              </p>
              <p class="black--text">
                <v-icon left color="naranja">
                  mdi-numeric-3-circle
                </v-icon>
                Realizar el examen de ubicación en el Aula Virtual
              </p>
              <p class="black--text">
                <v-icon left color="naranja">
                  mdi-numeric-4-circle
                </v-icon>
                Serás ubicad@ en el curso correspondiente a la puntuación
                obtenida en la prueba.
              </p>
              <p class="black--text">
                <v-icon left color="naranja">
                  mdi-numeric-5-circle
                </v-icon>
                Al ser ubicado se enviará un correo electrónico notificandote al
                correo <b>{{ student.email }}</b>
              </p>
              <p class="black--text">
                <v-icon left color="naranja">
                  mdi-numeric-6-circle
                </v-icon>
                Debes realizar el pago del curso en el que has sido ubicad@.
              </p>
              <p class="black--text">
                <v-icon left color="naranja">
                  mdi-numeric-7-circle
                </v-icon>
                Al realizar el pago, se vinculará tu usuario del Aula Virtual al
                nuevo curso.
              </p>
              <p class="black--text">
                <v-icon left color="naranja">
                  mdi-numeric-8-circle
                </v-icon>
                En esta misma ventana veras tu curso actual y su información
                correspondiente.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
          <v-card>
            <v-card-title>Mis Cursos</v-card-title>
            <v-card-subtitle>
              {{
                student.first_name +
                  " " +
                  student.last_name +
                  " (" +
                  student.code +
                  ")"
              }}
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
              <v-row justify="center">
                <v-expansion-panels accordion focusable flat active-class="1">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span>
                        <v-icon left>
                          mdi-medal
                        </v-icon>
                        Historial De Cursos
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <HistoryCourses></HistoryCourses>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-card>
            <v-card-title>Mi Perfil</v-card-title>
            <v-card-subtitle>
              {{
                student.first_name +
                  " " +
                  student.last_name +
                  " (" +
                  student.code +
                  ")"
              }}
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
              <v-row justify="center">
                <v-expansion-panels accordion focusable flat active-class="1">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span>
                        <v-icon left>
                          mdi-account
                        </v-icon>
                        Datos Personales
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <Information></Information>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span>
                        <v-icon text left>
                          mdi-key
                        </v-icon>
                        Contraseña
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ChangePassword></ChangePassword>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span>
                        <v-icon left>
                          mdi-account-supervisor
                        </v-icon>
                        Responables
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <Responsables></Responsables>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HistoryCourses from "./components/HistoryCourses";
import Information from "./components/Information";
import ChangePassword from "./components/ChangePassword";
import Responsables from "./components/Responsables";

export default {
  name: "StudentPortal",
  components: {
    HistoryCourses,
    Information,
    ChangePassword,
    Responsables,
  },
  data: () => ({
    current_course: [],
    cardLoad: true,
  }),
  mounted() {
    this.getCourses();
  },
  methods: {
    getCourses: async function() {
      this.cardLoad = true;
      await this.$http
        .get(this.api + "course_student/actual")
        .then(({ data }) => {
          if (data.course_schedules) {
            let horarios = data.course_schedules.split(",");
            data.course_schedules = horarios;
            this.current_course = data;
          }
        })
        .then(() => {
          this.cardLoad = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      student: "Student/getStudent",
    }),
  },
};
</script>

<style></style>
