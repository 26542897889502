<template>
  <div>
    <v-row v-if="student.status == 1 || student.status == 2">
      <v-col v-for="course in courses" :key="course.id_course" cols="12" md="4">
        <v-card outlined class="mt-3">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline text-center mb-1">
                {{ course.program }} {{ course.course }}
              </v-list-item-title>
              <v-list-item-subtitle class="mt-1 text-center">
                Calificación Obtenida:
                <br />
                <v-chip small outlined class="black--text mt-1">
                  <span class="d-flex items-center" v-if="course.final_score != null">
                    <span class="d-flex align-center">{{ course.final_score }}</span>
                    <v-icon color="green darken-3" v-if="studentAproved(course.final_score, course.minimum_score)">mdi-check</v-icon>
                    <v-icon v-else color="red darken-4">mdi-close</v-icon>
                  </span>
                  <span v-else> Nota Pendiente </span>
                </v-chip>
              </v-list-item-subtitle>
              <v-container v-if="!studentAproved(course.final_score, course.minimum_score)">
                <v-alert
                  border="top"
                  elevation="1"
                  colored-border
                  type="warning"
                >
                La nota obtenida en el módulo es inferior a la nota mínima para aprobar. Favor contactarse con el administrador de La Escuela de Idiomas para revisar su solvencia académica +503 6004-9160 (Whatsapp)
                </v-alert>
              </v-container>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" sm="12">
        <h4 class="text-center black--text">En espera de ubicación...</h4>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HistoryCourses",
  data: () => ({
    courses: [],
  }),
  mounted() {
    this.getCourses();
  },
  methods: {
    //actions
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    //funcionalidad
    getCourses: async function () {
      await this.$http
        .post(
          this.api + "course_student/student",
          this.toFormData(this.student)
        )
        .then(({ data }) => {
          this.courses = data;
        });
    },
    studentAproved(score, minimum) {
      return Number(score) >= Number(minimum) || score === null;
    }
  },
  watch: {
    student(val) {
      console.log(val);
    },
  },
  computed: {
    ...mapGetters({
      student: "Student/getStudent",
      api: "getApi",
    }),
    
  },
};
</script>

<style></style>
