<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          label="Nombres"
          required
          outlined
          hide-details
          v-model="student.first_name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          label="Apellidos"
          required
          outlined
          hide-details
          v-model="student.last_name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="student.birth_date"
              label="Fecha de Nacimiento"
              required
              outlined
              prepend-icon=""
              prepend-inner-icon="mdi-calendar"
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="student.birth_date"
            :max="minAge"
            :min="maxAge"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-select
          label="Género"
          :items="genders"
          item-text="gender"
          item-value="id_gender"
          required
          outlined
          hide-details
          v-model="student.id_gender"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          label="Teléfono"
          required
          outlined
          hide-details
          v-model="student.phone"
          v-mask="'####-####'"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          label="Correo electrónico"
          required
          outlined
          hide-details
          v-model="student.email"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-autocomplete
          label="Departmento"
          :items="departments"
          item-text="department"
          item-value="id_department"
          required
          outlined
          hide-details
          v-model="student.id_department"
          @change="getMunicipalities"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-autocomplete
          label="Municipio"
          :items="municipalities"
          item-text="municipality"
          item-value="id_municipality"
          required
          outlined
          hide-details
          v-model="student.id_municipality"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-textarea
          label="Dirección"
          required
          outlined
          rows="1"
          auto-grow
          hide-details
          v-model="student.address"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-file-input
          accept="image/png, image/jpeg"
          label="Foto de Perfil"
          placeholder="Seleccione una imagen"
          prepend-icon=""
          prepend-inner-icon="mdi-camera"
          outlined
          :show-size="1000"
          v-model="student.photo"
          @change="preview"
        />
      </v-col>
    </v-row>

    <v-row v-if="student.src">
      <v-col cols="12" sm="12">
        <v-img class="border" :src="student.src" height="150" contain />
      </v-col>
    </v-row>

    <v-divider />
    <v-row>
      <v-col cols="12" sm="12">
        <h3 class="black--text">Información Academica</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-autocomplete
          label="Departmento de la institución educativa"
          :items="sch_departments"
          item-text="department"
          item-value="id_department"
          required
          outlined
          hide-details
          v-model="student.id_department_school"
          @change="getSchMunicipalities"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-autocomplete
          label="Municipio de la institución educativa"
          :items="sch_municipalities"
          item-text="municipality"
          item-value="id_municipality"
          required
          outlined
          hide-details
          v-model="student.id_municipality_school"
          @change="getSchools"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-autocomplete
          label="Colegio/Escuela Académica Actual"
          :items="schools"
          item-text="school"
          item-value="id_school"
          required
          outlined
          hide-details
          v-model="student.id_school"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-autocomplete
          label="Grado Académico"
          :items="degrees"
          item-text="degree"
          item-value="id_degree"
          required
          outlined
          hide-details
          v-model="student.id_degree"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col cols="12" sm="12">
        <v-spacer></v-spacer>
        <v-btn tile color="primary" @click="update()" :loading="isLoading">
          <v-icon left>
            mdi-content-save
          </v-icon>
          Guardar Cambios
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  name: "StudentInformation",
  data: () => ({
    isLoading: false,
    date: null,
    menu: false,
    minAge: null,
    maxAge: null,
    //data
    genders: [],
    departments: [],
    municipalities: [],
    sch_departments: [],
    sch_municipalities: [],
    schools: [],
    degrees: [],
  }),
  mounted() {
    this.getGenders();
    this.getDegrees();
    this.getDepartments();
    // esto deberia ir en un watch pero no funciona ahi por alguna razon
    this.getMunicipalities();
    this.getSchMunicipalities();
    this.getSchools();
    //limites edades
    let min_date = moment()
      .subtract(7, "years")
      .format("Y-MM-DD");
    let max_date = moment()
      .subtract(90, "years")
      .format("Y-MM-DD");
    this.minAge = min_date;
    this.maxAge = max_date;
  },
  methods: {
    //actions
    ...mapActions({
      getStudent: "Student/getStudent",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    save(date) {
      this.$refs.menu.save(date);
    },
    //funcionalidad
    getGenders: function() {
      this.$http.get(this.apiCore + "gender_all").then(({ data }) => {
        this.genders = data;
      });
    },
    getDegrees: function() {
      this.$http.get(this.apiAis + "degree/all").then(({ data }) => {
        this.degrees = data;
      });
    },
    getDepartments: function() {
      this.$http.get(this.apiAdmision + "department/all").then(({ data }) => {
        this.departments = data;
        this.sch_departments = data;
      });
    },
    getMunicipalities: function() {
      let id = this.student.id_department;
      this.$http
        .get(this.apiAdmision + "municipality/department/" + id)
        .then(({ data }) => {
          this.municipalities = data;
        });
    },
    getSchMunicipalities: function() {
      let id = this.student.id_department_school;
      this.$http
        .get(this.apiAdmision + "municipality/department/" + id)
        .then(({ data }) => {
          this.sch_municipalities = data;
        });
    },
    getSchools: function() {
      let id = this.student.id_municipality_school;
      this.$http
        .get(this.apiAdmision + "school/municipality/" + id)
        .then(({ data }) => {
          this.schools = data;
        });
    },
    update: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.api + "student/" + this.student.id_student,
          this.toFormData(this.student)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.student.src = null;
            this.getStudent(this);
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    preview(event) {
      if (event) {
        this.student.src = URL.createObjectURL(event);
      }
    },
  },
  watch: {
    student(val) {
      console.log(val);
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapGetters({
      student: "Student/getStudent",
      api: "getApi",
      apiAdmision: "getAdmisionApi",
      apiAis: "getAisApi",
      apiCore: "getCoreApi",
    }),
  },
};
</script>

<style></style>
